













































































import Vue from 'vue'
import {
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import BCardCode from '@/vuexy/components/b-card-code'
import { togglePasswordFunc } from '@/vuexy/mixins/ui/forms'
import { codeMerged } from './code'

export default Vue.extend({
  components: {
    BCardCode,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  data() {
    return {
      codeMerged,
    }
  },
  setup() {
    const {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    } = togglePasswordFunc()

    return {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    }
  },
})
